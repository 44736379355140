<template>
  <div class="main">
    <div class="title">
      基于GAP的茶叶生产过程和质量安全<br />智能控制服务中心IT资源图
    </div>
    <img src="../assets/公有云.png"
         alt=""
         class="public"
         @click="this.$router.push({path: '/publicloud'})">
    <div class="jt1">
      <img src="../assets/橙色箭头.png"
           alt="">
    </div>
    <div class="bottom">
      <div class="dns">
        <span>防火墙、DNS</span>
      </div>

      <div>
        <div class="fAndN">
          <div>
            <div class="jt2">
              <img src="../assets/橙色箭头.png"
                   alt=""
                   class="left">
              <img src="../assets/橙色箭头.png"
                   alt=""
                   class="right">
            </div>
          </div>
          <img src="../assets/4号楼.png"
               alt=""
               class="four"
               @click="this.$router.push({path: '/buildingFour'})">
          <img src="../assets/绿色箭头.png"
               alt=""
               class="green">
          <img src="../assets/9号楼.png"
               alt=""
               class="nine"
               @click="this.$router.push({path: '/buildingNine'})">
        </div>
      </div>
    </div>

  </div>
</template>
<script>

</script>
<style scoped>
.main {
  background: #003469;
  width: 100%;
  height: 1400px;
}
.public {
  width: 35%;
  height: 280px;
}
.jt1 {
  margin-top: -60px;
}

.left {
  position: absolute;
  margin-left: 8%;
}
.right {
  margin-left: 70%;
}
.four {
  width: 50%;
  height: 500px;
}

.green {
  width: 20%;
  height: 80px;
  vertical-align: middle;
  margin-top: -500px;
  padding-left: -8% !important;
}
.nine {
  width: 30%;
  height: 500px;
}
.title {
  color: white;
  font-size: 36px;
  font-weight: bold;
}
.bottom {
  width: 80%;
  margin: 0 auto;
}
.dns {
  width: 100%;
  height: 50px;
  color: white;
  background: #3698ff;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: table;
}
span {
  vertical-align: middle;
  display: table-cell;
}
.public,.four,.nine{
  cursor: pointer;
}
</style>